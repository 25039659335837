





























































































































































































































































































































































































































































































































































































































.settings {
  justify-content: flex-end;
  flex-direction: column;
  display: flex;
  position: fixed;
  z-index: 950;
  top: 50px;
  right: 0;
  .q-btn {
    display: block;
    border-radius 0
  }
  .q-btn:last-child {
    border-bottom-left-radius 1rem
  }
}

.settings:hover {
  opacity: 1;
}

.q-th-header:last-child {
  padding-right: 60px;
}

.cell-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-top {
  max-height: 20px !important;
  padding: 8px 24px;
}

.popover {
}

.pointer {
  cursor: pointer;
}

.grey {
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }
}

.sp {
  padding: 10px;
  cursor: pointer;
}

tr {
  //background-color: red !important;
  &:hover {
    //background-color: red;
  }
}

.fulltable {
  table.q-table {
    //width: unset;
    //max-width unset;
  }
  .tablebottom {
    flex 1
    display flex;
    column-gap 1rem
    .lgselected {
      padding-left: 1rem;
    }
    .lgpagination {
      flex 1
      justify-content flex-end
      align-items: center;
      display flex
      column-gap: 1rem;
      .q-pagination {
        flex: 1;
        justify-content: center;
      }
      .lgrowperpage {
        .q-select {
          min-width: 150px
        }
      }
    }
  }
}
