





























































































































































































































































.footer {
  display: flex;
  justify-content: flex-end;
  padding: .2rem 1rem;
  >>> .q-field {
    padding: 0
    padding-right: 1rem
  }
}
.mainexport {
  height calc(100vh - 98px)
  .col {
    .padded {
      padding-top: 1rem;
    }
    .lg-a-chip {
      display inline-block
      border: 1px solid gray
      padding 0rem .5rem
      border-radius 1rem
      line-height: 2rem
      margin-right: .2rem
      .inline-box {
        display inline-block

      }
      .q-field {
        padding: 0;
        margin: 0 .2rem;
      }
      >>> .q-field__append, >>> .q-field__native, >>> .q-field__control {
        height: 26px
        min-height 26px
      }
      >>> .q-field__control {
        padding: 0 .2rem;
      }
    }
    >>> .vue-treeselect--open .vue-treeselect__control {
        border: 0;
        background-color: #f2f2f2;
        border-radius: 0;
    }
    >>> .vue-treeselect--open-below .vue-treeselect__menu {
      box-shadow none
      border: 0
    }
    >>> .vue-treeselect__menu {
      box-shadow none
      border: 0
    }
    padding: 1rem
    height: 100%;
    overflow: auto;

    .head1 {
      font-size 1.4rem
      .round {
        border-radius 50%
        width 2rem
        display inline-block
        text-align center
      }
    }
  }
}
