






















































































































































































































































































































table {
  border-collapse: collapse;
  table-layout: fixed;
  white-space: nowrap;

  tr {
    td {
      border-bottom: solid 1px rgba(0, 0, 0, 0.2);
      padding: 5px 30px;

      .drop-content {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .extend {
        font-size: 2rem;
        cursor: pointer;

        &:hover {
          font-size: 3rem;
        }
      }

      .content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .q-chip-right {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.topBottomLine {
        // opacity: 0.7;
        font-weight: bold;
      }
    }
  }
}
