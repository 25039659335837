.ctrl-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1em;
  align-items: center;
  padding: 0 1em;
}
.item-type3 {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-right: 6px;
  height: 110px;
  width: 75px;
  overflow: hidden;
}
.item-type3 p {
  margin-left: 5px;
  cursor: default;
}
.segmentation-button {
  width: 100%;
  margin-bottom: 1em;
  font-size: 0.84em;
  height: 50px;
}
.selected-text {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #ff8700;
  width: 100%;
  text-align: center;
  margin-top: 1em;
}
.content {
  display: flex;
  flex-direction: column;
  padding-top: 1em;
  border-bottom: 1px solid #ccc;
}
.drawerTitle {
  font-size: 1.2em;
  font-weight: bold;
  color: #444;
  width: 100%;
  text-align: center;
  margin-top: -0.25em;
  margin-bottom: 0.5em;
}
.add-icon {
  cursor: pointer;
  font-size: 1.4em;
}
.add-icon:hover {
  transform: scale(1.2);
}
.itemContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  max-height: 400px !important;
  overflow-y: scroll;
  padding: 0 1em;
}
.item-type1,
.item-type2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  height: 110px;
  width: 75px;
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 8px;
}
.item-type1:hover,
.item-type2:hover {
  background-color: #f0f0f0;
}
.item-type1 p,
.item-type2 p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  width: 100%;
}
.item-type1 img,
.item-type2 img {
  height: 50px;
  border: 1px solid #ccc;
  object-fit: contain;
  border-radius: 5px;
}
.item-type1 {
  flex-direction: column;
  position: relative;
}
.item-type1 .close-icon {
  position: absolute;
  top: 3px;
  right: 2px;
}
.item-type2 {
  flex-direction: row;
  justify-content: center;
  height: 40px;
  width: 115px;
}
.item-type2 p {
  text-align: center;
  font-size: 0.7em;
  font-weight: bold;
}
.close-icon {
  cursor: pointer;
  font-size: 1.1em;
  margin-left: 8px;
}
.close-icon:hover {
  color: #f00;
}
/*# sourceMappingURL=src/pages/products/drawer/segmentationDrawer.css.map */