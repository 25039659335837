










































































































































































































































































































































































































































































































































































































































































































































































































.settingheader {
  border-top: 1px solid black;
  position: relative;
  margin: 20px 0;
    margin-left: 0.5rem;
  .title {
    position: absolute;
    top: -10px;
    display: inline-block;
    background: white;
    padding: 0 10px;
    margin: 0 10px;
  }
  .recompute {
    position: absolute;
    right: 10px;
    top: -1rem;
  }
}
.pivotselect {
  outline:0;
  border:0;
}
.accordeon-params {
  font-weight: bold
  border-bottom: 1px solid black;
}
.pivottype, .multipivot-block {
  padding-right: 40px;
}
div.drawer-container {
  overflow auto
  height calc(100vh - 255px)
  .rule-name {
    padding 1rem
  }
  .ruleparams-fab {
    right 1rem
    bottom 1rem
  }

  .lg-common-settings {
    padding-left: .5rem;
    padding-right: 2.5rem;
  }
  .lg-other-settings {
    padding-left: 0.5rem;
    &.lg-force-right {
      margin-right: -40px;
    }
  }
  .common-check {
    background-color: #f2f2f2;
    padding-left: .5rem;
    margin: 1px
  }
  .pivot-actions {
    display: flex;
    gap: 0.5rem;
    padding-left: 0.5rem;
    padding-bottom: 0.1rem;
    .autopivot {
      flex: 1;
    }
    padding: 1rem;
  }
}
